import {Box, FormControl, FormHelperText, FormLabel, TextField} from "@mui/material";
import MaskedInput from "react-text-mask";
import React, {useRef} from "react";
import {CreatedProfileOutput, useCreateContractorMutation} from "../../api/sdl";
import {useFormik} from "formik";
import * as yup from "yup";
import {useSnackbar} from "notistack";
import {LoadingButton} from "@mui/lab";


const schemaFields: any = {
  email: yup
    .string()
    .email('Enter a valid email')
    .required('Email is required'),
  firstName: yup
    .string()
    .required('First name is required'),
  lastName: yup
    .string()
    .required('Last name is required'),
  phone: yup
    .string()
    .optional()
    // .required('Phone is required')
    .test(  'valid', 'Phone is not valid', value => {
      if(value === undefined) return true;

      return /^(\d{10})/gm.test(value.replace(/\D/g, ""))
    }),
  password: yup.string()
    .required("Password is required"),
  passwordConfirm: yup.string()
    .oneOf([yup.ref('password'), ''], 'Passwords must match')
    .required("Confirmation is required"),

}


export default function CreateContractorForm({onCompleted}: {onCompleted: (data: CreatedProfileOutput) => void}) {
  const { enqueueSnackbar } = useSnackbar();
  const ref = useRef();
  const [create, {loading}] = useCreateContractorMutation({
    onError: err => {
      if (err.graphQLErrors[0]?.message.includes('Email already registered')) {
        formik.setFieldError('email', err.graphQLErrors[0]?.message)
      } else {
        enqueueSnackbar(err.graphQLErrors[0]?.message || 'Unknown error', {variant: 'error'})
      }
    },
    onCompleted: data => {
      enqueueSnackbar('Your account was created successfully', {variant: 'success'});
      onCompleted(data.createContractor);
    },
  });

  const formik = useFormik({
    initialValues: {
      email: '',
      firstName: '',
      lastName: '',
      phone: '',
      password: '',
      passwordConfirm: '',
    },
    validationSchema: yup.object(schemaFields),
    onSubmit: (values) => {
      if (formik.isValid) {
        const {passwordConfirm, ...fields} = values;
        create({
          variables: {
            payload: {
              ...fields,
            }
          }
        })
      }
    },
    validateOnChange: true
  });



  return <Box ref={ref} component={'form'} onSubmit={formik.handleSubmit}>
    <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, minWidth: {md: '500px'}}}>
      <Box sx={{display: 'flex', flexDirection: 'column', gap: 3, alignSelf: 'end', width: 1}}>
            <FormControl sx={{flex: 1}}>
              <FormLabel>First Name</FormLabel>
              <TextField placeholder="First name"
                    name="firstName"
                    data-test="first-name"
                    autoFocus={true}
                    value={formik.values.firstName}
                    onChange={formik.handleChange}
                    error={formik.touched.firstName && Boolean(formik.errors.firstName)}
              />
              {(formik.touched.firstName && formik.errors.firstName) && <FormHelperText data-test='first-name-error' sx={{color: '#D3232F'}}>{formik.errors.firstName}</FormHelperText>}
            </FormControl>
            <FormControl sx={{flex: 1}}>
              <FormLabel>Last Name</FormLabel>
              <TextField placeholder="Last name"
                    name="lastName"
                    data-test='last-name'
                    value={formik.values.lastName}
                    onChange={formik.handleChange}
                    error={formik.touched.lastName && Boolean(formik.errors.lastName)}
              />
              {(formik.touched.lastName && formik.errors.lastName) && <FormHelperText data-test='last-name-error' sx={{color: '#D3232F'}}>{formik.errors.lastName}</FormHelperText>}
            </FormControl>
            <FormControl sx={{flex: 1}}>
              <FormLabel>Phone (optional)</FormLabel>
              <div className={`Masked-Input`}>
                <MaskedInput mask={['(', /\d/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                            name="phone"
                            data-test='phone'
                            placeholder="Phone"
                            value={formik.values.phone}
                            onChange={formik.handleChange}
                            className={`Masked-Input-input`}
                />
              </div>
              {(formik.touched.phone && formik.errors.phone) && <FormHelperText data-test='phone-error' sx={{color: '#D3232F'}}>{formik.errors.phone}</FormHelperText>}
            </FormControl>
            <FormControl sx={{flex: 1}}>
              <FormLabel>Email</FormLabel>
              <TextField placeholder="Email"
                    name="email"
                    data-test='email'
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
              />
              {(formik.touched.email && formik.errors.email) && <FormHelperText data-test='email-error' sx={{color: '#D3232F'}}>{formik.errors.email}</FormHelperText>}
            </FormControl>
            <FormControl sx={{flex: 1}}>
              <FormLabel>Password</FormLabel>
              <TextField placeholder="Password"
                    name="password"
                    data-test='password'
                    type={'password'}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
              />
              {(formik.touched.password && formik.errors.password) && <FormHelperText data-test='password-error' sx={{color: '#D3232F'}}>{formik.errors.password}</FormHelperText>}
            </FormControl>
            <FormControl sx={{flex: 1}}>
              <FormLabel>Repeat Password</FormLabel>
              <TextField placeholder="Repeat Password"
                    name="passwordConfirm"
                    type={'password'}
                    data-test='repeat-password'
                    value={formik.values.passwordConfirm}
                    onChange={formik.handleChange}
                    error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)}
              />
              {(formik.touched.passwordConfirm && formik.errors.passwordConfirm) && <FormHelperText data-test='repeat-password-error' sx={{color: '#D3232F'}}>{formik.errors.passwordConfirm}</FormHelperText>}
            </FormControl>
      </Box>
    </Box>
    <Box sx={{display: 'flex', justifyContent: 'center', mt: 5}}>
      <LoadingButton loading={loading}
                     id={"apply-as-contractor-button"}
                     variant={'contained'}
                     type={'submit'}
                     onClick={() => {
        if (!formik.isValid || !formik.dirty) {
          (ref.current as any)?.scrollIntoView()
        }
      }}>Apply to Join MDliaison</LoadingButton>
    </Box>
  </Box>
}
